<template>
	<div class="MflSecurity">
		<!-- 抬头标题 -->
		<div class="title-hang">
			<div class="fl-t">
				<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
			</div>
			<div class="zhuangshizb fl-t"></div>
			<div class="zhuangshizj fl-t">{{ projectData.project }}_安全设置</div>
			<div class="zhuangshiyb fl-t"></div>
			<div class="fr-t">
				<div class="fl-t">
					<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
				</div>
			</div>
		</div>
		<!-- 第一个表格 -->
<!--  
      <div class="anquanshezhi" >
       安全设置  
      </div> -->
      <div class="bigone">
        <!-- 上层单一列 -->
        <div class="fl-t">
          <div class="col1">
            <div class="c-r-t">给水限幅</div>
            <div class="c-r-b">
              <div>
                <div class="fl-t crb-t">主给水阀门调节上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__QBW_MAN1.OutT,'主给水阀门调节上限','OutT','RQM__p__QBW_MAN1')">{{infoList.RQM__p__QBW_MAN1.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">主给水阀门调节下限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__QBW_MAN1.OutB,'主给水阀门调节下限','OutB','RQM__p__QBW_MAN1')">{{infoList.RQM__p__QBW_MAN1.OutB}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">1#给水泵电流上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.MKG.SBI_PVA_HL,'1#给水泵电流上限','SBI_PVA_HL','MKG')">{{infoList.MKG.SBI_PVA_HL}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">2#给水泵电流上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.MKG.SBI_PVB_HL,'2#给水泵电流上限','SBI_PVB_HL','MKG')">{{infoList.MKG.SBI_PVB_HL}}</div>
              </div>
            </div>
          </div>  
        
          <div class="col2">
              <div class="c-r-t">送风限幅</div>
              <div class="c-r-b">
                <div>
                  <div class="fl-t crb-t">1#送风电流上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.MKG.SFI_PVA_HL,'1#送风电流上限','SFI_PVA_HL','MKG')">{{infoList.MKG.SFI_PVA_HL}}</div>
                </div>
                <div>
                  <div class="fl-t crb-t">2#送风电流上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.MKG.SFI_PVB_HL,'2#送风电流上限','SFI_PVB_HL','MKG')">{{infoList.MKG.SFI_PVB_HL}}</div>
                </div>
                <div>
                  <div class="fl-t crb-t">1#送风开度上限</div>   <div class="fl-t crb-b"
                   @click="toIpt(infoList.RQM__p__YQO_MAN1.OutT,'1#送风开度上限','OutT','RQM__p__YQO_MAN1')"
                      >{{infoList.RQM__p__YQO_MAN1.OutT}}</div>
                </div>
                <div>
                  <div class="fl-t crb-t">1#送风开度下限</div>   <div class="fl-t crb-b"
                    @click="toIpt(infoList.RQM__p__YQO_MAN1.OutB,'1#送风开度下限','OutB','RQM__p__YQO_MAN1')"
                      >{{infoList.RQM__p__YQO_MAN1.OutB}}</div>
                </div>
                <div>
                  <div class="fl-t crb-t">2#送风开度上限</div>   <div class="fl-t crb-b"
                 @click="toIpt(infoList.RQM__p__YQO_MAN2.OutT,'2#送风开度上限','OutT','RQM__p__YQO_MAN2')"
                      >{{infoList.RQM__p__YQO_MAN2.OutT}}</div>
                </div>
                <div>
                  <div class="fl-t crb-t">2#送风开度下限</div>   <div class="fl-t crb-b"
                   @click="toIpt(infoList.RQM__p__YQO_MAN2.OutB,'2#送风开度下限','OutB','RQM__p__YQO_MAN2')"
                      >{{infoList.RQM__p__YQO_MAN2.OutB}}</div>
                </div>
              </div> 
          </div>  
      </div>
        
        
        <!-- 上层第二列 -->
        <div class="fl-t">
          <div class="col1">
            <div class="c-r-t">一次风限幅</div>
            <div class="c-r-b">
              <div>
                <div class="fl-t crb-t">1#一次风上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__YCF_MAN1.OutT,'1#一次风上限','OutT','RQM__p__YCF_MAN1')">{{infoList.RQM__p__YCF_MAN1.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">1#一次风下限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__YCF_MAN1.OutB,'1#一次风下限','OutB','RQM__p__YCF_MAN1')">{{infoList.RQM__p__YCF_MAN1.OutB}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">2#一次风上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__YCF_MAN2.OutT,'2#一次风上限','OutT','RQM__p__YCF_MAN2')">{{infoList.RQM__p__YCF_MAN2.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">2#一次风下限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__YCF_MAN2.OutB,'2#一次风下限','OutB','RQM__p__YCF_MAN2')">{{infoList.RQM__p__YCF_MAN2.OutB}}</div>
              </div>
              
            </div>
          </div> 
        
        <div class="col2">
            <div class="c-r-t">引风限幅</div>
            <div class="c-r-b">
              <div>
                <div class="fl-t crb-t">1#引风电流上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.MKG.YFI_PVA_HL,'1#引风电流上限','YFI_PVA_HL','MKG')">{{infoList.MKG.YFI_PVA_HL}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">2#引风电流上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.MKG.YFI_PVB_HL,'2#引风电流上限','YFI_PVB_HL','MKG')">{{infoList.MKG.YFI_PVB_HL}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">1#引风开度上限</div>   <div class="fl-t crb-b"
                 @click="toIpt(infoList.RQM__p__LTP_MAN1.OutT,'1#引风开度上限','OutT','RQM__p__LTP_MAN1')"
                    >{{infoList.RQM__p__LTP_MAN1.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">1#引风开度下限</div>  <div class="fl-t crb-b"
                   @click="toIpt(infoList.RQM__p__LTP_MAN1.OutB,'1#引风开度下限','OutB','RQM__p__LTP_MAN1')"
                    >{{infoList.RQM__p__LTP_MAN1.OutB}}</div>
              </div>     
              <div>
                <div class="fl-t crb-t">2#引风开度上限</div>  <div class="fl-t crb-b"
                  @click="toIpt(infoList.RQM__p__LTP_MAN2.OutT,'2#引风开度上限','OutT','RQM__p__LTP_MAN2')"
                    >{{infoList.RQM__p__LTP_MAN2.OutT}}</div>
              </div>      
              <div>
                <div class="fl-t crb-t">2#引风开度下限</div>   <div class="fl-t crb-b"
                  @click="toIpt(infoList.RQM__p__LTP_MAN2.OutB,'2#引风开度下限','OutB','RQM__p__LTP_MAN2')"
                    >{{infoList.RQM__p__LTP_MAN2.OutB}}</div>
              </div>
            </div>          
          </div>
        </div>
        <!-- 上层第三列 -->
        <div class="fl-t">
          <div class="col3">
            <div class="c-r-t">温度限幅</div>
            <div class="c-r-b">
              <div>
                <div class="fl-t crb-t">一级左减温上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__ZQT_MAN1.OutT,'一级左减温上限','OutT','RQM__p__ZQT_MAN1')">{{infoList.RQM__p__ZQT_MAN1.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">一级左减温下限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__ZQT_MAN1.OutB,'一级左减温下限','OutB','RQM__p__ZQT_MAN1')">{{infoList.RQM__p__ZQT_MAN1.OutB}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">一级右减温上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__ZQT_MAN2.OutT,'一级右减温上限','OutT','RQM__p__ZQT_MAN2')">{{infoList.RQM__p__ZQT_MAN2.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">一级右减温下限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__ZQT_MAN2.OutB,'一级右减温下限','OutB','RQM__p__ZQT_MAN2')">{{infoList.RQM__p__ZQT_MAN2.OutB}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">二级左减温上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__ZQT_MAN3.OutT,'二级左减温上限','OutT','RQM__p__ZQT_MAN3')">{{infoList.RQM__p__ZQT_MAN3.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">二级左减温下限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__ZQT_MAN3.OutB,'二级左减温下限','OutB','RQM__p__ZQT_MAN3')">{{infoList.RQM__p__ZQT_MAN3.OutB}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">二级右减温上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__ZQT_MAN4.OutT,'二级右减温上限','OutT','RQM__p__ZQT_MAN4')">{{infoList.RQM__p__ZQT_MAN4.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">二级右减温下限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__ZQT_MAN4.OutB,'二级右减温下限','OutB','RQM__p__ZQT_MAN4')">{{infoList.RQM__p__ZQT_MAN4.OutB}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">再热左开度上限</div>    <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__ZQT_MAN5.OutT,'再热左开度上限','OutT','RQM__p__ZQT_MAN5')">{{infoList.RQM__p__ZQT_MAN5.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">再热左开度下限</div>    <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__ZQT_MAN5.OutB,'再热左开度下限','OutB','RQM__p__ZQT_MAN5')">{{infoList.RQM__p__ZQT_MAN5.OutB}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">再热右开度上限</div>    <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__ZQT_MAN6.OutT,'再热右开度上限','OutT','RQM__p__ZQT_MAN6')">{{infoList.RQM__p__ZQT_MAN6.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">再热右开度下限</div>    <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__ZQT_MAN6.OutB,'再热右开度下限','OutB','RQM__p__ZQT_MAN6')">{{infoList.RQM__p__ZQT_MAN6.OutB}}</div>
              </div>
            </div>
            
        </div>
        </div>
        <!-- 上层第四列 -->
        <div class="fl-t">
          <div class="col3">
            <div class="c-r-t">给煤限幅</div>
            <div class="c-r-b">
              <div>
                <div class="fl-t crb-t">A磨给煤调节上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__GMJ_MAN1.OutT,'A磨给煤调节上限','OutT','RQM__p__GMJ_MAN1')">{{infoList.RQM__p__GMJ_MAN1.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">A磨给煤调节下限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__GMJ_MAN1.OutB,'A磨给煤调节下限','OutB','RQM__p__GMJ_MAN1')">{{infoList.RQM__p__GMJ_MAN1.OutB}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">B磨给煤调节上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__GMJ_MAN2.OutT,'B磨给煤调节上限','OutT','RQM__p__GMJ_MAN2')">{{infoList.RQM__p__GMJ_MAN2.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">B磨给煤调节下限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__GMJ_MAN2.OutB,'B磨给煤调节下限','OutB','RQM__p__GMJ_MAN2')">{{infoList.RQM__p__GMJ_MAN2.OutB}}</div>
              </div>
               <div>
                <div class="fl-t crb-t">C磨给煤调节上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__GMJ_MAN3.OutT,'C磨给煤调节上限','OutT','RQM__p__GMJ_MAN3')">{{infoList.RQM__p__GMJ_MAN3.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">C磨给煤调节下限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__GMJ_MAN3.OutB,'C磨给煤调节下限','OutB','RQM__p__GMJ_MAN3')">{{infoList.RQM__p__GMJ_MAN3.OutB}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">D磨给煤调节上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__GMJ_MAN4.OutT,'D磨给煤调节上限','OutT','RQM__p__GMJ_MAN4')">{{infoList.RQM__p__GMJ_MAN4.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">D磨给煤调节下限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__GMJ_MAN4.OutB,'D磨给煤调节下限','OutB','RQM__p__GMJ_MAN4')">{{infoList.RQM__p__GMJ_MAN4.OutB}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">E磨给煤调节上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__GMJ_MAN5.OutT,'E磨给煤调节上限','OutT','RQM__p__GMJ_MAN5')">{{infoList.RQM__p__GMJ_MAN5.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">E磨给煤调节下限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__GMJ_MAN5.OutB,'E磨给煤调节下限','OutB','RQM__p__GMJ_MAN5')">{{infoList.RQM__p__GMJ_MAN5.OutB}}</div>
              </div>
             
            </div>
            
        </div>
        </div>
        <!-- 上层第五列 -->
        <div class="fl-t">
          <div class="col3">
            <div class="c-r-t">热风门限幅</div>
            <div class="c-r-b">
              <div>
                <div class="fl-t crb-t">A磨风量调节上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__MFL_MAN1.OutT,'A磨风量调节上限','OutT','RQM__p__MFL_MAN1')">{{infoList.RQM__p__MFL_MAN1.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">A磨风量调节下限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__MFL_MAN1.OutB,'A磨风量调节下限','OutB','RQM__p__MFL_MAN1')">{{infoList.RQM__p__MFL_MAN1.OutB}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">B磨风量调节上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__MFL_MAN2.OutT,'B磨风量调节上限','OutT','RQM__p__MFL_MAN2')">{{infoList.RQM__p__MFL_MAN2.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">B磨风量调节下限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__MFL_MAN2.OutB,'B磨风量调节下限','OutB','RQM__p__MFL_MAN2')">{{infoList.RQM__p__MFL_MAN2.OutB}}</div>
              </div>
               <div>
                <div class="fl-t crb-t">C磨风量调节上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__MFL_MAN3.OutT,'C磨风量调节上限','OutT','RQM__p__MFL_MAN3')">{{infoList.RQM__p__MFL_MAN3.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">C磨风量调节下限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__MFL_MAN3.OutB,'C磨风量调节下限','OutB','RQM__p__MFL_MAN3')">{{infoList.RQM__p__MFL_MAN3.OutB}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">D磨风量调节上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__MFL_MAN4.OutT,'D磨风量调节上限','OutT','RQM__p__MFL_MAN4')">{{infoList.RQM__p__MFL_MAN4.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">D磨风量调节下限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__MFL_MAN4.OutB,'D磨风量调节下限','OutB','RQM__p__MFL_MAN4')">{{infoList.RQM__p__MFL_MAN4.OutB}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">E磨风量调节上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__MFL_MAN5.OutT,'E磨给煤调节上限','OutT','RQM__p__MFL_MAN5')">{{infoList.RQM__p__MFL_MAN5.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">E磨风量调节下限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__MFL_MAN5.OutB,'E磨风量调节下限','OutB','RQM__p__MFL_MAN5')">{{infoList.RQM__p__MFL_MAN5.OutB}}</div>
              </div>
             
            </div>
            
        </div>
        </div>       
        <!-- 上层第六列  -->
        <div class="fl-t">
          <div class="col3">
            <div class="c-r-t">冷风门限幅</div>
            <div class="c-r-b">
              <div>
                <div class="fl-t crb-t">A磨温度调节上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__CKT_MAN1.OutT,'A磨温度调节上限','OutT','RQM__p__CKT_MAN1')">{{infoList.RQM__p__CKT_MAN1.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">A磨温度调节下限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__CKT_MAN1.OutB,'A磨温度调节下限','OutB','RQM__p__CKT_MAN1')">{{infoList.RQM__p__CKT_MAN1.OutB}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">B磨温度调节上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__CKT_MAN2.OutT,'B磨温度调节上限','OutT','RQM__p__MFL_MAN2')">{{infoList.RQM__p__CKT_MAN2.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">B磨温度调节下限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__CKT_MAN2.OutB,'B磨温度调节下限','OutB','RQM__p__MFL_MAN2')">{{infoList.RQM__p__CKT_MAN2.OutB}}</div>
              </div>
               <div>
                <div class="fl-t crb-t">C磨温度调节上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__CKT_MAN3.OutT,'C磨温度调节上限','OutT','RQM__p__MFL_MAN3')">{{infoList.RQM__p__CKT_MAN3.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">C磨温度调节下限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__CKT_MAN3.OutB,'C磨温度调节下限','OutB','RQM__p__MFL_MAN3')">{{infoList.RQM__p__CKT_MAN3.OutB}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">D磨温度调节上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__CKT_MAN4.OutT,'D磨温度调节上限','OutT','RQM__p__MFL_MAN4')">{{infoList.RQM__p__CKT_MAN4.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">D磨温度调节下限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__CKT_MAN4.OutB,'D磨温度调节下限','OutB','RQM__p__MFL_MAN4')">{{infoList.RQM__p__CKT_MAN4.OutB}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">E磨温度调节上限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__CKT_MAN5.OutT,'E磨温度调节上限','OutT','RQM__p__CKT_MAN5')">{{infoList.RQM__p__CKT_MAN5.OutT}}</div>
              </div>
              <div>
                <div class="fl-t crb-t">E磨温度调节下限</div>   <div class="fl-t crb-b" @click="toIpt(infoList.RQM__p__CKT_MAN5.OutB,'E磨温度调节下限','OutB','RQM__p__CKT_MAN5')">{{infoList.RQM__p__CKT_MAN5.OutB}}</div>
              </div>
             
            </div>
            
        </div>
        </div>  
        <!-- 中层 -->
        <div class="fl-t  zhongceng">
          <div class="co1 fl-t">
            <div class="co1-t fl-t">脱硝限幅</div>
              <div class="co1-b fl-t">
               <div class="co1-bt fl-t">脱硝A阀门上限</div>
               <div class="co1-bb fl-t"
              @click="toIpt(infoList.MKG.TX_MVA_HL,'脱硝A阀门上限','TX_MVA_HL','MKG')"
                    >{{infoList.MKG.TX_MVA_HL}}</div>
              
              <div class="co1-bt fl-t">脱硝B阀门上限</div>
               <div class="co1-bb fl-t"
                  @click="toIpt(infoList.MKG.TX_MVB_HL,'脱硝B阀门上限','TX_MVB_HL','MKG')"
                    >{{infoList.MKG.TX_MVB_HL}}</div>
             </div>
             <div class="co1-b fl-t">
               <div class="co1-bt fl-t">脱硝A阀门下限</div>
               <div class="co1-bb fl-t"
                 @click="toIpt(infoList.MKG.TX_MVA_LL,'脱硝A阀门下限','TX_MVA_LL','MKG')"
                    >{{infoList.MKG.TX_MVA_LL}}</div>
                <div class="co1-bt fl-t">脱硝B阀门下限</div>
                <div class="co1-bb fl-t"
                  @click="toIpt(infoList.MKG.TX_MVB_LL,'脱硝B阀门下限','TX_MVB_LL','MKG')"
                    >{{infoList.MKG.TX_MVB_LL}}</div>
             </div>
          </div>
          <!-- 中层右边 -->
          <div class="co2 fl-t">
            <div class="co1-t fl-t">脱硫限幅</div>
             <div class="co1-b fl-t">
               <div class="co1-bt fl-t">脱硫A阀门上限</div>
               <div class="co1-bb fl-t"
                  @click="toIpt(infoList.MKG.TL_MVA_HL,'脱硫A阀门上限','TL_MVA_HL','MKG')"
                    >{{infoList.MKG.TL_MVA_HL}}</div>
               <div class="co1-bt fl-t">脱硫B阀门上限</div>
               <div class="co1-bb fl-t"
                  @click="toIpt(infoList.MKG.TL_MVB_HL,'脱硫B阀门上限','TL_MVB_HL','MKG')"
                    >{{infoList.MKG.TL_MVB_HL}}</div>
             </div>
             <div class="co1-b fl-t">
               <div class="co1-bt fl-t">脱硫A阀门下限</div>
               <div class="co1-bb fl-t"
                  @click="toIpt(infoList.MKG.TL_MVA_LL,'脱硫A阀门下限','TL_MVA_LL','MKG')"
                    >{{infoList.MKG.TL_MVA_LL}}</div>
               <div class="co1-bt fl-t">脱硫B阀门下限</div>
               <div class="co1-bb fl-t"
                   @click="toIpt(infoList.MKG.TL_MVB_LL,'脱硫B阀门下限','TL_MVB_LL','MKG')"
                    >{{infoList.MKG.TL_MVB_LL}}</div>
             </div>
          </div>         
       </div>
      </div>
        <div class="baojing_t">报警设置</div>
    <!-- 报警设置第一块 -->
    <div class="baojing_col fl-t">
      <!-- 一行 -->
      <div>
        <div class="col_1 fl-t"
         @click="
                  toIpt(
                    infoList.KHG.ZQP_ALARM_EN,
                    ' 汽压上限',
                     'ZQP_ALARM_EN',
                    'KHG',
                   
                  )
                ">
          <el-switch  v-model="infoList.KHG.ZQP_ALARM_EN"
                                            active-color="#29DFAE"
                                            inactive-color="red"
                                             disabled
                                            >
          </el-switch>
          
        </div>
      
        <div class="col_2 fl-t">
            汽压上限 
        </div>
        <div class="col_3 fl-t" @click="toIpt(infoList.KHG.ZQP_ALARM_H,'汽压上限','ZQP_ALARM_H','KHG')">{{infoList.KHG.ZQP_ALARM_H}}</div>
        
      </div>
      <!-- 第二行 -->
      <div>
        <div class="col_1 fl-t">
          <!-- <el-switch  v-model="infoList.KHG.PV"
                                            active-color="#29DFAE"
                                            inactive-color="red"
                                             disabled
                                            >
          </el-switch> -->
        </div>
        <div class="col_2 fl-t">
            汽压下限
        </div>
          <div class="col_3 fl-t" @click="toIpt(infoList.KHG.ZQP_ALARM_L,'汽压下限','ZQP_ALARM_L','KHG')">{{infoList.KHG.ZQP_ALARM_L}}</div>

      </div>
      <!-- 第三行 -->
      <div>
        <div class="col_1 fl-t"
          @click="
                  toIpt(
                    infoList.KHG.ZQT_ALARM_EN,
                    ' 汽温上限',
                     'ZQT_ALARM_EN',
                    'KHG',
                   
                  )
                ">
          <el-switch  v-model="infoList.KHG.ZQT_ALARM_EN"
                                            active-color="#29DFAE"
                                            inactive-color="red"
                                             disabled
                                            >
          </el-switch>
        </div>
        <div class="col_2 fl-t">
            汽温上限
        </div>
        <div class="col_3 fl-t" @click="toIpt(infoList.KHG.ZQT_ALARM_H,'汽温上限','ZQT_ALARM_H','KHG')">{{infoList.KHG.ZQT_ALARM_H}}</div>
      </div>
            <!-- 第四行 -->
      <div>
        <div class="col_1 fl-t">
          <!-- <el-switch  v-model="infoList.KHG.PV"
                                            active-color="#29DFAE"
                                            inactive-color="red"
                                             disabled
                                            >
          </el-switch> -->
        </div>
        <div class="col_2 fl-t">
            汽温下限
        </div>
        <div class="col_3 fl-t" @click="toIpt(infoList.KHG.ZQT_ALARM_L,'汽温下限','ZQT_ALARM_L','KHG')">{{infoList.KHG.ZQT_ALARM_L}}</div>
      </div>     
    </div>

    <!-- 报警第二块 -->
    <div class="baojing_col fl-t">
      <!-- 一行 -->
      <div>
        <div class="col_1 fl-t"
           @click="
                  toIpt(
                    infoList.KHG.ZRQT_ALARM_EN,
                    ' 再热汽温上限',
                     'ZRQT_ALARM_EN',
                    'KHG',
                   
                  )
                ">
          <el-switch  v-model="infoList.KHG.ZRQT_ALARM_EN"
                                            active-color="#29DFAE"
                                            inactive-color="red"
                                             disabled
                                            >
          </el-switch>
        </div>
        <div class="col_2 fl-t">
            再热汽温上限
        </div>
        <div class="col_3 fl-t" @click="toIpt(infoList.KHG.ZRQT_ALARM_H,'再热汽温上限','ZRQT_ALARM_H','KHG')">{{infoList.KHG.ZRQT_ALARM_H}}</div>
      </div>
      <!-- 第二行 -->
      <div>
        <div class="col_1 fl-t">
          <!-- <el-switch  v-model="infoList.KHG.PV"
                                            active-color="#29DFAE"
                                            inactive-color="red"
                                             disabled
                                            >
          </el-switch> -->
        </div>
        <div class="col_2 fl-t">
            再热汽温下限
        </div>
        <div class="col_3 fl-t" @click="toIpt(infoList.KHG.ZRQT_ALARM_L,'再热汽温下限','ZRQT_ALARM_L','KHG')">{{infoList.KHG.ZRQT_ALARM_L}}</div>
      </div>
      <!-- 第三行 -->
      <div>
        <div class="col_1 fl-t"
                  @click="
                  toIpt(
                    infoList.KHG.QBW_ALARM_EN,
                    ' 水位上限',
                     'QBW_ALARM_EN',
                    'KHG',
                   
                  )
                ">
          <el-switch  v-model="infoList.KHG.QBW_ALARM_EN"
                                            active-color="#29DFAE"
                                            inactive-color="red"
                                             disabled
                                            >
          </el-switch>
        </div>
        <div class="col_2 fl-t">
            水位上限
        </div>
        <div class="col_3 fl-t" @click="toIpt(infoList.KHG.QBW_ALARM_H,'水位上限','QBW_ALARM_H','KHG')">{{infoList.KHG.QBW_ALARM_H}}</div>
      </div>
            <!-- 第四行 -->
      <div>
        <div class="col_1 fl-t">
          <!-- <el-switch  v-model="infoList.KHG.PV"
                                            active-color="#29DFAE"
                                            inactive-color="red"
                                             disabled
                                            >
          </el-switch> -->
        </div>
        <div class="col_2 fl-t">
            水位下限
        </div>
        <div class="col_3 fl-t" @click="toIpt(infoList.KHG.QBW_ALARM_L,'水位下限','QBW_ALARM_L','KHG')">{{infoList.KHG.QBW_ALARM_L}}</div>

      </div>     
    </div>

    <!-- 报警第三块 -->
    <div class="baojing_col fl-t">
      <!-- 一行 -->
      <div>
        <div class="col_1 fl-t"
          @click="
                  toIpt(
                    infoList.KHG.YQO_ALARM_EN,
                    ' 氧量上限',
                     'YQO_ALARM_EN',
                    'KHG',
                   
                  )
                ">
          <el-switch  v-model="infoList.KHG.YQO_ALARM_EN"
                                            active-color="#29DFAE"
                                            inactive-color="red"
                                             disabled
                                            >
          </el-switch>
        </div>
        <div class="col_2 fl-t">
            氧量上限
        </div>
        <div class="col_3 fl-t" @click="toIpt(infoList.KHG.YQO_ALARM_H,'氧量上限','YQO_ALARM_H','KHG')">{{infoList.KHG.YQO_ALARM_H}}</div>

      </div>
      <!-- 第二行 -->
      <div>
        <div class="col_1 fl-t">
          <!-- <el-switch  v-model="infoList.KHG.PV"
                                            active-color="#29DFAE"
                                            inactive-color="red"
                                             disabled
                                            >
          </el-switch> -->
        </div>
        <div class="col_2 fl-t">
             氧量下限
        </div>
        <div class="col_3 fl-t" @click="toIpt(infoList.KHG.YQO_ALARM_L,'氧量下限','YQO_ALARM_L','KHG')">{{infoList.KHG.YQO_ALARM_L}}</div>

      </div>
      <!-- 第三行 -->
      <div>
        <div class="col_1 fl-t"
          @click="
                  toIpt(
                    infoList.KHG.LTP_ALARM_EN,
                    ' 负压上限',
                     'LTP_ALARM_EN',
                    'KHG',
                   
                  )
                ">
          <el-switch  v-model="infoList.KHG.LTP_ALARM_EN"
                                            active-color="#29DFAE"
                                            inactive-color="red"
                                             disabled
                                            >
          </el-switch>
        </div>
        <div class="col_2 fl-t">
            负压上限
        </div>
        <div class="col_3 fl-t" @click="toIpt(infoList.KHG.LTP_ALARM_H,'负压上限','LTP_ALARM_H','KHG')">{{infoList.KHG.LTP_ALARM_H}}</div>

      </div>
            <!-- 第四行 -->
      <div>
        <div class="col_1 fl-t">
          <!-- <el-switch  v-model="infoList.KHG.PV"
                                            active-color="#29DFAE"
                                            inactive-color="red"
                                             disabled
                                            >
          </el-switch> -->
        </div>
        <div class="col_2 fl-t">
            负压下限
        </div>
        <div class="col_3 fl-t" @click="toIpt(infoList.KHG.LTP_ALARM_L,'负压下限','LTP_ALARM_L','KHG')">{{infoList.KHG.LTP_ALARM_L}}</div>
      </div>     
    </div>

    <!-- 报警第四块 -->
    <div class="baojing_col fl-t">
      <!-- 一行 -->
      <div>
        <div class="col_1 fl-t"
         @click="
                  toIpt(
                    infoList.KHG.CKTA_ALARM_EN,
                    '  1#磨温度上限',
                     'CKTA_ALARM_EN',
                    'KHG',
                   
                  )
                ">
          <el-switch  v-model="infoList.KHG.CKTA_ALARM_EN"
                 
                                            active-color="#29DFAE"
                                            inactive-color="red"
                                             disabled
                                            >
                              
          </el-switch>
        </div>
        <div class="col_2 fl-t">
            1#磨温度上限
        </div>
        <div class="col_3 fl-t" @click="toIpt(infoList.KHG.CKTA_ALARM_H,'1#磨温度上限','CKTA_ALARM_H','KHG')">{{infoList.KHG.CKTA_ALARM_H}}</div>

      </div>
      <!-- 第二行 -->
      <div>
        <div class="col_1 fl-t"
          @click="
                  toIpt(
                    infoList.KHG.CKTB_ALARM_EN,
                    ' 2#磨温度上限',
                     'CKTB_ALARM_EN',
                    'KHG',
                   
                  )
                ">
          <el-switch  v-model="infoList.KHG.CKTB_ALARM_EN"
                                            active-color="#29DFAE"
                                            inactive-color="red"
                                             disabled
                                            >
          </el-switch>
        </div>
        <div class="col_2 fl-t">
            2#磨温度上限
        </div>
        <div class="col_3 fl-t" @click="toIpt(infoList.KHG.CKTB_ALARM_H,'2#磨温度上限','CKTB_ALARM_H','KHG')">{{infoList.KHG.CKTB_ALARM_H}}</div>
      </div>
      <!-- 第三行 -->
      <div>
        <div class="col_1 fl-t"
          @click="
                  toIpt(
                    infoList.KHG.CKTC_ALARM_EN,
                    '3#磨温度上限',
                     'CKTC_ALARM_EN',
                    'KHG',
                   
                  )
                ">
          <el-switch  v-model="infoList.KHG.CKTC_ALARM_EN"
                                            active-color="#29DFAE"
                                            inactive-color="red"
                                             disabled
                                            >
          </el-switch>
        </div>
        <div class="col_2 fl-t">
            3#磨温度上限
        </div>
        <div class="col_3 fl-t" @click="toIpt(infoList.KHG.CKTC_ALARM_H,'3#磨温度上限','CKTC_ALARM_H','KHG')">{{infoList.KHG.CKTC_ALARM_H}}</div>

      </div>
      <!-- 第四行 -->
      <div>
        <div class="col_1 fl-t"
            @click="
                  toIpt(
                    infoList.KHG.CKTD_ALARM_EN,
                    '4#磨温度上限',
                     'CKTD_ALARM_EN',
                    'KHG',
                   
                  )
                ">
          <el-switch  v-model="infoList.KHG.CKTD_ALARM_EN"
                                            active-color="#29DFAE"
                                            inactive-color="red"
                                             disabled
                                            >
          </el-switch>
        </div>
        <div class="col_2 fl-t">
            4#磨温度上限
        </div>
        <div class="col_3 fl-t" @click="toIpt(infoList.KHG.CKTD_ALARM_H,'4#磨温度上限','CKTD_ALARM_H','KHG')">{{infoList.KHG.CKTD_ALARM_H}}</div>

      </div>
      <!-- 第5行 -->
      <div>
        <div class="col_1 fl-t"
            @click="
                  toIpt(
                    infoList.KHG.CKTE_ALARM_EN,
                    '5#磨温度上限',
                     'CKTE_ALARM_EN',
                    'KHG',
                   
                  )
                ">
          <el-switch  v-model="infoList.KHG.CKTE_ALARM_EN"
                                            active-color="#29DFAE"
                                            inactive-color="red"
                                             disabled
                                            >
          </el-switch>
        </div>
        <div class="col_2 fl-t">
            5#磨温度上限
        </div>
        <div class="col_3 fl-t" @click="toIpt(infoList.KHG.CKTE_ALARM_H,'5#磨温度上限','CKTE_ALARM_H','KHG')">{{infoList.KHG.CKTE_ALARM_H}}</div>
      </div>      
    </div>

    <!-- 报警第三块 -->
    <div class="baojing_col fl-t">
      <!-- 一行 -->
      <div>
        <div class="col_1 fl-t"
           @click="
                  toIpt(
                    infoList.KHG.TL_ALARM_EN,
                    'SO2上限',
                     'TL_ALARM_EN',
                    'KHG',
                   
                  )
                ">
          <el-switch  v-model="infoList.KHG.TL_ALARM_EN"
                                            active-color="#29DFAE"
                                            inactive-color="red"
                                             disabled
                                            >
          </el-switch>
        </div>
        <div class="col_2 fl-t">
            SO2上限
        </div>
        <div class="col_3 fl-t" @click="toIpt(infoList.KHG.TL_ALARM_H,'SO2上限','TL_ALARM_H','KHG')">{{infoList.KHG.TL_ALARM_H}}</div>
      </div>
      <!-- 第二行 -->
      <div>
        <div class="col_1 fl-t"
         @click="
                  toIpt(
                    infoList.KHG.TX_ALARM_EN,
                    'NO2上限',
                     'TX_ALARM_EN',
                    'KHG',
                   
                  )
                ">
          <el-switch  v-model="infoList.KHG.TX_ALARM_EN"
                                            active-color="#29DFAE"
                                            inactive-color="red"
                                             disabled
                                            >
          </el-switch>
        </div>
        <div class="col_2 fl-t">
            NO2上限
        </div>
        <div class="col_3 fl-t" @click="toIpt(infoList.KHG.TX_ALARM_H,'NO2上限','TX_ALARM_H','KHG')">{{infoList.KHG.TX_ALARM_H}}</div>
      </div>    
    </div>

    <!-- 报警第五块 -->
    <div class="baojing_col fl-t">
      <!-- 一行 -->
      <div>
        <div class="col_1 fl-t"
          @click="
                  toIpt(
                    infoList.KHG.BCS1_EN,
                    ' 优化站异常',
                     'BCS1_EN',
                    'KHG',
                   
                  )
                ">
          <el-switch  v-model="infoList.KHG.BCS1_EN"
                                            active-color="#29DFAE"
                                            inactive-color="red"
                                             disabled
                                            >
          </el-switch>
        </div>
        <div class="col_2 fl-t">
            优化站异常
        </div>
        <div class="col_3 fl-t">
           
        </div>
      </div>
      <!-- 第二行 -->
      <div>
        <div class="col_1 fl-t"
         @click="
                  toIpt(
                    infoList.KHG.GMJD_ALARM_EN,
                    ' 断煤报警',
                     'GMJD_ALARM_EN',
                    'KHG',
                   
                  )
                ">
          <el-switch  v-model="infoList.KHG.GMJD_ALARM_EN"
                                            active-color="#29DFAE"
                                            inactive-color="red"
                                             disabled
                                            >
          </el-switch>
        </div>
        <div class="col_2 fl-t">
            断煤报警
        </div>
        <div class="col_3 fl-t">
          
        </div>
      </div>   
    </div>
    
	 <Historical v-if="isHshow" @sendStatus = 'isHshow=false' :historyname = 'historyname' :node='node' :Lkname='Lkname' :infoList = 'infoList'></Historical>
	 <Firstcontrol 
	 :infoList = 'infoList' 
	 :isIndex = 'isIndex'
	 :historyname = 'Firstcontrolname'
	 :titname = 'Firstcontroltitname'
	 :node = 'Firstcontrolnode'
	 v-if="isFshow"
	 @sendStatus = 'isFshow=false'></Firstcontrol>
	 <Rsf v-if="isRshow" @sendStatus = 'isRshow=false' :titname = 'Rsftitname' :infoList = 'infoList' :historyname = 'Rsfname' :node = 'Rsfnode'></Rsf>
	 <ParameterYh v-if="isComShow"  :infoList = 'infoList' @sendStatus = 'isshowfase'></ParameterYh>
	 <Manual
	   :key="isIndex"
	   v-if="isMshow"
	   @sendStatus="isMshow = false"
	   :historyname="Manualname"
	   :node="Manualnode"
	   :Lkname="ManualAname"
	   :titname="Manualtitname"
	   :infoList="infoList">
	 </Manual>
    <inputVal ref="inputVal" @getTreeData='DataJson(arguments)'></inputVal>
</div>

</template>



<script>

import inputVal from "@/components/inputVal.vue"   //输入框组件
import Historical from "@/components/Historical.vue"  //历史趋势
import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
import Rsf from "@/components/RSF.vue"; //阮伺服组件
import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
import Manual from "@/components/Manual.vue"; //手操器组件
export default {
    name: "MflSecurity",
     props:{
        infoList:{
            type:Object,
            default: {} // 默认值
        }
    },
 components:{inputVal, Historical,Firstcontrol,Rsf,ParameterYh,Manual},
    data: () => {
		return {
       Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
         isComShow:false,
      isHshow:false,
      isMshow:false,
      isRshow:false,
      historyname:'',
      node:'',
      Lkname:'',
      isIndex:'',
      Firstcontrolname:'',
      Firstcontroltitname:'',
      Firstcontrolnode:'',
      isFshow:false,
      Rsfname:'',
      Rsfnode:'',
      Rsftitname:'',
      projectData:'',
      grouptime:null,
      spotArr:[],
      authInfo:[],
      UserInfo:[],
		}
        
	},
  created(){
     this.projectData = JSON.parse(localStorage.getItem("deviceType"))
     this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
     		this.authInfo = JSON.parse(localStorage.getItem("autharr"))
		this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
  },
  methods:{
    		// 双击事件
		Cclick(num,mark,node,name){
		// 组装数据
		 clearTimeout(this.grouptime);
		let spojobj = {
			num:num,
			mark:mark,
			node:node,
			name:name
		}
		this.spotArr.push(spojobj)
		// 数组去重
		let deWeightThree = () => {
			let map = new Map();
			for (let item of this.spotArr) {
				if (!map.has(item.name)) {
					this.$message.closeAll()
					map.set(item.name, item);
					this.$message.success('已添加');
				}else{
					this.$message.closeAll()
					this.$message.error('已添加到变量池里，切勿重复添加');
				}
			}
			return [...map.values()];
		}
		this.spotArr = deWeightThree();
		localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
	},
    		// 打开先控多窗口
		toDetail(key,name,nodename,Aname,titname){
			if(!this.authInfo[1]){
				let str = this.authInfo[0]
				// 判断是否为调试还是查看权限
				if (str.charAt(str.length-1) == "r") {
					this.$message.error('暂无调试权限');
				}
			}else{
				let pathname=""
				switch(key){
					case 1:
					pathname = 'firstcontol'
					break
					case 2:
				    pathname = 'kfirstcontol'
					break
				}
				const newurl = this.$router.resolve({name: pathname, query: {Firstcontrolname: name,Firstcontrolnode:nodename,Firstcontroltitname:titname}})
				window.open(newurl.href,'_blank')
			}
		},
         // 双击事件
    Cclick(num,mark,node,name){
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num:num,
        mark:mark,
        node:node,
        name:name
      }
      this.spotArr.push(spojobj)
      // 数组去重
      let deWeightThree = () => {
          let map = new Map();
          for (let item of this.spotArr) {
              if (!map.has(item.name)) {
                 this.$message.closeAll()
                  map.set(item.name, item);
                 this.$message.success('已添加');
              }else{
                this.$message.closeAll()
                this.$message.error('已添加到变量池里，切勿重复添加');
              }
          }
       
          return [...map.values()];
      }
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
    },
       isshowfase(){
       this.isComShow = false
     },
    closeCompon(){

      this.$emit('sendStatus', 'JRLParam02',false)
    },
    isClose(val,val1){
      switch(val){
        case 'Historical':
          return this.isHshow = val1
      }
    },
      DataJson(data){
              console.log(data)
      let strNode = data[0]
      let strMark = data[1]
        this.$nextTick(() => {
       this.infoList[strNode][strMark] = data[2]     
      });
    },
    // 打开下置输入窗口
    toIpt(data, name, historyname, node, type) {
      if(!this.authInfo[1]){
				let str = this.authInfo[0]
				// 判断是否为调试还是查看权限
        console.log(str.charAt(str.length-1))
				if (str.charAt(str.length-1) == "r") {
					this.$message.error('暂无操作权限');
				}
			}else{
        this.$refs.inputVal.open(data, name, historyname, node, type);
      }
      
    },
    toCompon(key,name,name2,name3,titname){
			this.isIndex = key
			this.historyname = name
			this.node = name2
			this.Lkname = name3
      clearTimeout(this.grouptime);
      this.grouptime=setTimeout(() =>{
        		switch(key){
				  case 0:
          return this.isMshow = true,this.Manualname = name,this.Manualnode = name2,this.ManualAname = name3,this.Manualtitname = titname
        case 1 :
          return this.isFshow = true,this.Firstcontrolname = name,this.Firstcontrolnode = name2,this.Firstcontroltitname = titname 
        case 2:
          return  this.isHshow = true
        case 4:
				return  this.isRshow = true,this.Rsfname = name,this.Rsfnode = name2,this.Rsftitname = titname
			}
      },300);    
		},
    } 
}
</script>


<style lang="scss" scoped>
 .fl-t{
   float: left;
 }
 .fr-t {
   float: right;
 }
::v-deep{
  .el-switch.is-disabled .el-switch__core{
                cursor: pointer !important;
            }
   .el-switch.is-disabled{
                           opacity: 1 !important;
       }
}
 .title-hang {
   height: 5vh;
   width: 96vw;
   font-family: MicrosoftYaHei;
   font-size:1vw;
   font-weight: normal;
   font-stretch: normal;
   line-height: 2vh;
   letter-spacing: 0vh;
 }
  .zhuangshizb {
   width: 14vw;
   height: 3vh;
   background-image: url("~@/assets/images/rfl_evaluate06.png");
   background-size: 100% 100%;
   margin-left: 3.5vw;
   margin-top: 1.5vh;
 }
 .zhuangshizj {
   width: 26vw;
   height: 3vh;
   line-height:4vh;
   margin-top:1vh;
   text-align: center;
   background-image: url("~@/assets/images/rfl_evaluate05.png");
   background-size: 100% 100%;
   margin-left: 5vw;
   font-family: MicrosoftYaHei;
   font-size: 1.5vw;
   font-weight: normal;
   font-stretch: normal;
   letter-spacing: 0vh;
   color: #0ef7ff;
 }
 .zhuangshiyb {
   width: 14vw;
   height: 3vh;
   background-image: url("~@/assets/images/rfl_evaluate04.png");
   background-size: 100% 100%;
   margin-left: 5vw;
   margin-top: 1.5vh;
 }

 .anquanshezhi{
  width: 100vw;
  // margin-left: 1vw;
  text-align: center;
  line-height: 3vh;
  -webkit-box-align: center;/*旧版本*/
  -moz-box-align: center;/*旧版本*/
  -ms-flex-align: center;/*混合版本*/
  -webkit-align-items: center;/*新版本*/
  align-items: center;/*新版本*/
	height: 3vh;
	font-family: PingFang-SC-Regular;
	font-size: 3vh;
	font-weight: normal;
	font-stretch: normal;

	letter-spacing: 1vh;
	color: #d5fffe;
 
 
 }
 .bigone{
   cursor:pointer;
  width: 100%;
  margin-left: 1.5vw;
	height: 23vw;
  margin-top: 1vh;
	// background-color: #00e4ff20;
  .col1{
    width: 16vw;
    height: 16vh;
    border:1px solid #0ef7ff20;
    
  }
  .col2{
    width: 16vw;
    height: 21vh;
    border:1px solid #0ef7ff20;
    border-top: 0px; 
  }
  .col3{
    padding-bottom: 1px;
    width: 15.3vw;
    height: 37vh;
    border:1px solid #0ef7ff20;
    
  }
  .c-r-t{
    font:1.2vw sans-serif;
    font-weight: 400;
    font-family: PingFang-SC-Regular;
    text-align: center;
    color: #458ca4;
    border-bottom: 1px solid #2fc3e320;
  }
  .c-r-b{
    
    font-size:1vw;
    // font-weight: 400;
    font-family: PingFang-SC-Regular;
    text-align: center;
    
    
    .crb-t{
      width: 75%;
      color: #2fc3e3;
      height: 3vh;
    }
    .crb-b{
      width: 25%;
      color: #e0f2f7;
       height: 3vh;
    }
  }
  .zhongceng{
    width: 98.7%;
    height: 9vh;
    border: 1px solid #0ef7ff20;
    .co1{
      width:50.7%;
      height: 9vh;
      border-right: 1px solid #0ef7ff20;
      .co1-t{
        width: 12vw;
        height: 9vh;
        text-align: center;
        line-height: 9vh;
        border-right:1px solid  #0ef7ff20;
        font-family: PingFang-SC-Regular;
        font-size:1vw;
        font-weight: normal;
        font-stretch: normal; 
        letter-spacing: 0vw;
        color: #2fc3e3;
      }
      .co1-b{
        width: 35.2vw;
        height:4vh;
        font-family: PingFang-SC-Regular;
        font-size:1vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 4vh;
        letter-spacing: 0vw;

        color: #6acafb;
        .co1-bt{
          width: 10vw;
          text-align: center;
          

        }
        .co1-bb{
          width: 7vw;
            color: #e0f2f7;
        }
      }
    }
    .co2{
      width: 46vw;
      height: 9vh;
      border-right: 1px solid #0ef7ff20;
      .co1-t{
        width: 10vw;
        height: 9vh;
        text-align: center;
        line-height: 9vh;
        border-right:1px solid  #0ef7ff20;
        font-family: PingFang-SC-Regular;
        font-size:1vw;
        font-weight: normal;
        font-stretch: normal; 
        letter-spacing: 0vw;
        color: #2fc3e3;
      }
      .co1-b{
        width: 35vw;
        height:4vh; 
        font-family: PingFang-SC-Regular;
        font-size:1vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 4vh;
        letter-spacing: 0vw;
        color: #6acafb;
        .co1-bt{
          width: 10vw;
          text-align: center;

        }
        .co1-bb{
          width: 7vw;
          color: #e0f2f7;
        }
      }
    }
  }
 }
 .baojing_t{
    text-align: center;
    font-family: PingFang-SC-Regular;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0vw;
    color: #d5fffe;
    margin-bottom: 1vh;
 }
.baojing_col{
   width: 13vw;
   height: 20vh;
   margin-left: 1vw;
   border: 1px solid #0ef7ff20;
   padding:2vh 1.2vw;
   .col_1{
      width: 3vw;
      height: 3vh;
      line-height: 4vh;
   }
   .col_2{
      text-align: center;
      width: 7vw;
      height: 3vh;
      font-family: PingFang-SC-Regular;
      font-size:1vw;
      font-weight: normal;
      font-stretch: normal;
      line-height: 4vh;
      letter-spacing: 0vw;
      color: #6acafb;
    }
    .col_3{
        text-align: center;
      	width: 3vw;
        height: 3vh;
        font-family: PingFang-SC-Regular;
        font-size:1vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 4vh;
        letter-spacing: 0vw;
        color: #d9f8ff;
    }

 }
</style>